import { APIHelper } from './api-helper';
import { Router } from '@angular/router';
import { Component, OnInit, Output,ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription ,  Subject } from 'rxjs';
import { CaptainAppService } from './services/captain-app.service';
import { KotHelper } from './kot-helper';
import { remove } from 'lodash-es';
import { WebSocketService } from './services/websocket.service';

@Component({
  selector: 'captain-app-helper',
  template: ``,
})

export class CaptainAppHelper {

    public subscriptions: Subscription[] = [];
    public modalRef: BsModalRef;
    public notificationSource = new Subject<any>();
    private unsubscribe() {
        this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    constructor(private router:Router,private messageService:MessageService,
        private captainAppService:CaptainAppService,
        private modalService: BsModalService,private stateGuard: StateGuardService,
        private kotHelper: KotHelper,
        private webSocketService: WebSocketService ){
    }

    ngOnInit() {
        this.subscriptions.push(
          this.webSocketService.getMessages().subscribe((msg) => {
            console.log('Received from server:', msg);
    
            if (msg.type === 'get_tables_response') {
    
            }
          })
        );
      }




    syncOrder(currentUnit) {
        var isCaptainAppAvailable =   this.isCaptainAppAvailable();
        if(currentUnit && isCaptainAppAvailable){
            let sendData = [];
            var self = this;

            let customer_info = {
                phone: (currentUnit.userInfo && currentUnit.userInfo.mobile_no) ? currentUnit.userInfo.mobile_no : '0000000000'
            }
            let temp_client_reference_code = (currentUnit.visitors && currentUnit.visitors[0] && currentUnit.visitors[0].temp_client_reference_code) ? currentUnit.visitors[0].temp_client_reference_code : null;
            let tempObject = {                
                customer_info              : customer_info,
                temp_client_reference_code : temp_client_reference_code,
                kot_request_description    : (currentUnit.kot_request_description) ? currentUnit.kot_request_description : ""
            }
            
            if (Array.isArray(currentUnit.visitors)) {
                let tempVisitors = [];
                currentUnit.visitors.forEach(visitor => {

                    let tempDishes = [];
                    let tempKot = [];                    
                    if (Array.isArray(visitor.dishes) && visitor.dishes[0]) {
                        visitor.dishes[0].forEach(itemInfo => {

                            let tempAttributes = [];

                            if (itemInfo.selected_variant_item) {
                                let tempItemData = {
                                    category_code: itemInfo.selected_variant_item.category_code,
                                    item_code: itemInfo.selected_variant_item.item_code,
                                    item_count: itemInfo.selected_variant_item.item_count,
                                    description: itemInfo.selected_variant_item.description,
                                    note: itemInfo.selected_variant_item.note,
                                    item_price: itemInfo.selected_variant_item.unit_price,
                                    discount: itemInfo.selected_variant_item.discount ? itemInfo.selected_variant_item.discount : 0,
                                    discount_type: itemInfo.selected_variant_item.discountUnits ? itemInfo.selected_variant_item.discountUnits : null,
                                    item_from: itemInfo.selected_variant_item.item_from,
                                }
                                if (Array.isArray(itemInfo.selected_variant_item.attributes)) {
                                    itemInfo.selected_variant_item.attributes.forEach(attributeDetails => {
                                        tempAttributes.push(
                                            {    
                                                attribute_code: attributeDetails.attribute_code,
                                                count: attributeDetails.count,
                                            }
                                        )
                                    })
                                }
                                tempItemData['attributes'] = tempAttributes;
                                tempDishes.push(tempItemData);

                            } else {

                                let tempItemData = {
                                    category_code: itemInfo.category_code,
                                    item_code: itemInfo.item_code,
                                    item_count: itemInfo.item_count,
                                    description: itemInfo.description,
                                    note: itemInfo.note,
                                    item_price: itemInfo.unit_price,
                                    discount: itemInfo.discount ? itemInfo.discount : 0,
                                    discount_type: itemInfo.discountUnits ? itemInfo.discountUnits : null,
                                    item_from: itemInfo.item_from,
                                }

                                if (Array.isArray(itemInfo.attributes)) {
                                    itemInfo.attributes.forEach(attributeDetails => {
                                        tempAttributes.push(
                                            {
                                                attribute_code: attributeDetails.attribute_code,
                                                count: attributeDetails.count,
                                            }
                                        )
                                    })
                                }
                                tempItemData['attributes'] = tempAttributes;
                                tempDishes.push(tempItemData);
                            }
                        })
                    }
                    if (Array.isArray(visitor.kot) && visitor.kot[0]) {

                        visitor.kot.forEach(kotInfo => {
                            let tempKotData = {
                                kot_id: kotInfo.kot_id,
                                kot_number: kotInfo.kot_number,
                                kot_description:(kotInfo.kot_description)? kotInfo.kot_description : ""
                            }
                            let tempKotItems = [];
                            if (Array.isArray(kotInfo.items) && kotInfo.items[0]) {
                                kotInfo.items.forEach(kotItemInfo => {
                                    let tempKotItemAttributes = [];
                                    let tempKotItemData = {
                                        category_code: kotItemInfo.category_code,
                                        item_code: kotItemInfo.item_code,
                                        description: (kotItemInfo.description) ? kotItemInfo.description : "",
                                        note: (kotItemInfo.note) ? kotItemInfo.note : "",
                                        item_count: kotItemInfo.item_count,
                                        item_price: (kotItemInfo.unit_price)? parseFloat(kotItemInfo.unit_price) :parseFloat(kotItemInfo.item_price),
                                        discount: kotItemInfo.discount ? kotItemInfo.discount : 0,
                                        discount_type: kotItemInfo.discountUnits ? kotItemInfo.discountUnits : null,
                                        item_from: kotItemInfo.item_from,
                                        is_billed: kotItemInfo.is_billed,
                                        item_status: (kotItemInfo.item_status) ? kotItemInfo.item_status : "new",
                                        delete_reason: kotItemInfo.delete_reason ? kotItemInfo.delete_reason : null,
                                    }
                                    if (Array.isArray(kotItemInfo.attributes)) {
                                        kotItemInfo.attributes.forEach(attributeDetails => {
                                            tempKotItemAttributes.push(
                                                {
                                                    attribute_code: attributeDetails.attribute_code,
                                                    count: attributeDetails.count,
                                                }
                                            )
                                        })
                                    }
                                    tempKotItemData['attributes'] = tempKotItemAttributes;
                                    tempKotItems.push(tempKotItemData);
                                })
                                tempKotData['items'] = tempKotItems;
                            }
                            tempKot.push(tempKotData);
                        })
                    }
                    tempVisitors.push(
                        {
                            items: tempDishes,
                            kot: tempKot
                        }
                    )
                })
                tempObject['visitors'] = tempVisitors;
                this.captainAppService.saveOrderWithCaptainApp(tempObject).subscribe((res) => {
                var apiRes = res.json();

                if (apiRes.status === 0) {
                } else {                  
                    this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
                }
            },err =>{                
                this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
            });
            }
        }
    }

    syncTable(currentUnit,isAlreadySync) {
        var isCaptainAppAvailable =   this.isCaptainAppAvailable();
        if(currentUnit && isCaptainAppAvailable){
            let sendData = [];
            var self = this;

            let customer_info = {
                phone: (currentUnit.userInfo && currentUnit.userInfo.mobile_no) ? currentUnit.userInfo.mobile_no : '0000000000'
            }

            let temp_client_reference_code = (currentUnit.visitors && currentUnit.visitors[0] && currentUnit.visitors[0].temp_client_reference_code) ? currentUnit.visitors[0].temp_client_reference_code : null;
            let tempObject = {
                section_name               : currentUnit.section_name? currentUnit.section_name : currentUnit.location,
                tables                     : currentUnit.tables,
                covers                     : currentUnit.covers? currentUnit.covers : 0,
                started_at                 : currentUnit.activePhase? currentUnit.activePhase.timeStamp : null,
                assigny                    : currentUnit.assigny,
                table_status               : currentUnit.table_status,
                lock_index                 : currentUnit.lock_index, 
                customer_info              : customer_info,
                temp_client_reference_code : temp_client_reference_code,
                kot_request_description    : (currentUnit.kot_request_description) ? currentUnit.kot_request_description : "",
                kot_request_number         : (currentUnit.kot_request_number) ? currentUnit.kot_request_number : 0,
                kot_ready_number           : (currentUnit.kot_ready_number) ? currentUnit.kot_ready_number : 0,
                is_checkout_call           : (currentUnit.is_checkout_call) ? true : false,
            }
            if (currentUnit.isFromOnHold) {
                tempObject['table_status'] = 'hold';
            }/* else if (currentUnit.isOnHold) {
                tempObject['table_status'] = 'hold';
            }*/

            if (Array.isArray(currentUnit.visitors)) {
                let tempVisitors = [];
                currentUnit.visitors.forEach(visitor => {

                    let tempDishes = [];
                    let tempKot = [];

                    let deleted_items = [];

                    if(visitor.deleted_items){
                        deleted_items = visitor.deleted_items;
                    }

                    if (Array.isArray(visitor.dishes) && visitor.dishes[0]) {
                        visitor.dishes[0].forEach(itemInfo => {

                            let tempAttributes = [];

                            if (itemInfo.selected_variant_item) {
                                let tempItemData = {
                                    category_code: itemInfo.selected_variant_item.category_code,
                                    item_code: itemInfo.selected_variant_item.item_code,
                                    item_count: itemInfo.selected_variant_item.item_count,
                                    description: itemInfo.selected_variant_item.description,
                                    note: itemInfo.selected_variant_item.note,
                                    item_price: itemInfo.selected_variant_item.unit_price,
                                    discount: itemInfo.selected_variant_item.discount ? itemInfo.selected_variant_item.discount : 0,
                                    discount_type: itemInfo.selected_variant_item.discountUnits ? itemInfo.selected_variant_item.discountUnits : null,
                                    item_from: itemInfo.selected_variant_item.item_from,
                                }
                                if (Array.isArray(itemInfo.selected_variant_item.attributes)) {
                                    itemInfo.selected_variant_item.attributes.forEach(attributeDetails => {
                                        tempAttributes.push(
                                            {    
                                                attribute_code: attributeDetails.attribute_code,
                                                count: attributeDetails.count,
                                            }
                                        )
                                    })
                                }
                                tempItemData['attributes'] = tempAttributes;
                                tempDishes.push(tempItemData);

                            } else {

                                let tempItemData = {
                                    category_code: itemInfo.category_code,
                                    item_code: itemInfo.item_code,
                                    item_count: itemInfo.item_count,
                                    description: itemInfo.description,
                                    note: itemInfo.note,
                                    item_price: itemInfo.unit_price,
                                    discount: itemInfo.discount ? itemInfo.discount : 0,
                                    discount_type: itemInfo.discountUnits ? itemInfo.discountUnits : null,
                                    item_from: itemInfo.item_from,
                                }

                                if (Array.isArray(itemInfo.attributes)) {
                                    itemInfo.attributes.forEach(attributeDetails => {
                                        tempAttributes.push(
                                            {
                                                attribute_code: attributeDetails.attribute_code,
                                                count: attributeDetails.count,
                                            }
                                        )
                                    })
                                }
                                tempItemData['attributes'] = tempAttributes;
                                tempDishes.push(tempItemData);
                            }
                        })
                    }
                    if (Array.isArray(visitor.kot) && visitor.kot[0]) {

                        visitor.kot.forEach(kotInfo => {
                            let tempKotData = {
                                kot_id: kotInfo.kot_id,
                                kot_number: kotInfo.kot_number,
                                kot_description:(kotInfo.kot_description)? kotInfo.kot_description : ""
                            }
                            let tempKotItems = [];
                            if (Array.isArray(kotInfo.items) && kotInfo.items[0]) {
                                kotInfo.items.forEach(kotItemInfo => {
                                    let tempKotItemAttributes = [];
                                    let tempKotItemData = {
                                        category_code: kotItemInfo.category_code,
                                        item_code: kotItemInfo.item_code,
                                        description: (kotItemInfo.description) ? kotItemInfo.description : "",
                                        note: (kotItemInfo.note) ? kotItemInfo.note : "",
                                        item_count: kotItemInfo.item_count,
                                        item_price: (kotItemInfo.unit_price)? parseFloat(kotItemInfo.unit_price) :parseFloat(kotItemInfo.item_price),
                                        discount: kotItemInfo.discount ? kotItemInfo.discount : 0,
                                        discount_type: kotItemInfo.discountUnits ? kotItemInfo.discountUnits : null,
                                        item_from: kotItemInfo.item_from,
                                        is_billed: kotItemInfo.is_billed,
                                        item_status: (kotItemInfo.item_status) ? kotItemInfo.item_status : "new",
                                        delete_reason: kotItemInfo.delete_reason ? kotItemInfo.delete_reason : null,
                                    }
                                    if (Array.isArray(kotItemInfo.attributes)) {
                                        kotItemInfo.attributes.forEach(attributeDetails => {
                                            tempKotItemAttributes.push(
                                                {
                                                    attribute_code: attributeDetails.attribute_code,
                                                    count: attributeDetails.count,
                                                }
                                            )
                                        })
                                    }
                                    tempKotItemData['attributes'] = tempKotItemAttributes;
                                    tempKotItems.push(tempKotItemData);
                                })
                                tempKotData['items'] = tempKotItems;
                            }
                            tempKot.push(tempKotData);
                        })
                    }
                    tempVisitors.push(
                        {
                            items: tempDishes,
                            kot: tempKot,
                            deleted_items:deleted_items
                        }
                    )
                })
                tempObject['visitors'] = tempVisitors;
            }
            if(!tempObject.lock_index) {tempObject.lock_index = 0};
            this.syncParticularTable(JSON.parse(JSON.stringify(tempObject)),true,isAlreadySync);
        }
    }

    syncItems(items){
        var isCaptainAppAvailable =   this.isCaptainAppAvailable();
        if(isCaptainAppAvailable){
            let sendData = {
              shop_items:items,
              is_clear:true
            }
            this.captainAppService.syncItemsInfo(sendData).subscribe((res) => {
                var apiRes = res.json();

                if (apiRes.status === 0) {
                } else {
                  //this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });
                }
            },err =>{
                this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
            });
        }
    }

    setInClearing(data,condition) {
        let allPlaces = this.stateGuard.obtain("selects-box.component:places") || [];
        if(allPlaces) {
            let foundSection = allPlaces.filter(ap => ap.name == data.section_name)[0];
            if(foundSection && foundSection.units) {
                var foundTable = foundSection.units.filter(fs => fs.tables[0] == data.tables[0])[0];
                if(foundTable) {                    
                    if(!condition) {
                        //below delay put so next sync table data come after clear the table
                        setTimeout(() => {
                            foundTable['is_clearing'] = false
                        },5002);
                    } else {
                        foundTable['is_clearing'] = condition;                    
                    }
                }
            }
        }
    }

    syncParticularTable(data,isIncrementLock_index,isAlreadySynced){
        var isCaptainAppAvailable = this.isCaptainAppAvailable();
        if(isCaptainAppAvailable){
            
            if(data.is_clear || data.is_checkout){
                this.setInClearing(data,true)
            }
            this.captainAppService.syncParticularTable(data).subscribe((res) => {
                var apiRes = res.json();
                let allPlaces = this.stateGuard.obtain("selects-box.component:places") || [];
                let  foundTable = null;
                if(allPlaces) {
                    let foundSection = allPlaces.filter(ap => ap.name == data.section_name)[0];
                    if(foundSection && foundSection.units) {
                        foundTable = foundSection.units.filter(fs => fs.tables[0] == data.tables[0])[0];                        
                    }
                }

                if (apiRes.status === 0) {

                    //update lock index
                    if(foundTable) {
                        foundTable['is_clearing'] = false;

                        if(isIncrementLock_index) {
                            foundTable.lock_index = parseInt(foundTable.lock_index) + 1;
                        } else {
                            foundTable.lock_index = 0;
                        }
                    }
                } else {
                    let sendData ={
                        section_name : data.section_name,
                        tables : data.tables,
                    }
                    if(!isAlreadySynced){
                        this.getParticularTableInfo(sendData,data,isIncrementLock_index,true);
                    }else{
                        if(foundTable) {
                            foundTable['is_clearing'] = false;
                        }
                    }
                }
            },err =>{
                this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
            });
        }
    }

    getParticularTableInfo(data,sendData,clearOrSaveTableFlag,isAlreadySync){

        this.captainAppService.getParticularSyncTable(data).subscribe((res) => {
            var apiRes = res.json();
            if (apiRes.status === 0) {
                let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
                this.mergeParticularTable(apiRes.table_info,sendData,clearOrSaveTableFlag,isAlreadySync);
            } else {
                this.setInClearing(sendData,false)
                //this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });
            }
        },err =>{
            this.setInClearing(sendData,false)
            this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
        });
    }

    mergeParticularTable(tableinfo,sendData,clearOrSaveTableFlag,isAlreadySync){

        var self = this;
        let allPlaces = this.stateGuard.obtain("selects-box.component:places") || [];
        
        if(tableinfo && sendData.is_clear && !clearOrSaveTableFlag) {
            sendData.lock_index = tableinfo.lock_index;
            self.syncParticularTable(sendData,clearOrSaveTableFlag,isAlreadySync);
        } else if(tableinfo && allPlaces) {
            let foundSection = allPlaces.filter(ap => ap.name == tableinfo.section_name)[0];
            if(foundSection && foundSection.units) {
                var foundTable = foundSection.units.filter(fs => fs.tables[0] == tableinfo.tables[0])[0];

                if(foundTable) {//&& foundTable.lock_index > tableinfo.lock_index

                    foundTable.lock_index = tableinfo.lock_index;
                    foundTable.assigny = tableinfo.assigny;
                    foundTable.covers = tableinfo.covers;
                    foundTable.kot_ready_number = tableinfo.kot_ready_number;
                    foundTable.kot_request_number = tableinfo.kot_request_number;
                    foundTable.kot_request_description = tableinfo.kot_request_description;
                    foundTable.started_at = tableinfo.started_at;
                    foundTable.table_status = tableinfo.table_status;
                    foundTable.temp_client_reference_code = tableinfo.temp_client_reference_code;
                    
                    let localConfig = JSON.parse(localStorage.getItem('local_configuration')) || {};
                    if(( !localConfig || localConfig.disable_captain_notifications != true) && !foundTable.is_checkout_call && tableinfo.is_checkout_call) {
                        let oInfo:any = {
                            tables: tableinfo.tables,
                            section_name: tableinfo.section_name,
                            captain_user_name: tableinfo.captain_terminal && tableinfo.captain_terminal.terminal_name ? tableinfo.captain_terminal.terminal_name : ""
                        }
                        this.showNotificationForCheckOutOrder(JSON.parse(JSON.stringify(oInfo)));
                    }

                    if(tableinfo.is_checkout_call) {
                        foundTable.is_checkout_call = true;
                        if(foundTable.activePhase && foundTable.activePhase.name){
                            foundTable.activePhase.name = 'blue';
                        }
                    } else {
                        foundTable.is_checkout_call = false;
                        if(foundTable.activePhase && foundTable.activePhase.name){
                            foundTable.activePhase.name = 'green';
                        }
                    }
                    if(tableinfo.tables.length > 0) {
                        for(var i=1;i<tableinfo.tables.length;i++) {
                            remove(foundSection.units, fs => fs.tables[0] == tableinfo.tables[i]);
                        }

                    }
                    foundTable.tables = tableinfo.tables;

                    if(Array.isArray(tableinfo.visitors)) {
                        tableinfo.visitors.forEach(visitor => {
                            if(visitor.items) {
                                var captainItems = visitor.items.filter(vi => vi.item_from == "captain");
                                var tempCaptainItems = [];
                                if(captainItems) {
                                    captainItems.forEach(item => {
                                        item =  this.convertCaptainItemToPosItem(item);
                                        tempCaptainItems.push(item)
                                    })
                                    if(!foundTable.visitors) {
                                        //console.log("item add",tempCaptainItems)
                                        foundTable.visitors = [{}];
                                        foundTable.visitors[0]["dishes"] = [tempCaptainItems,[]];
                                        foundTable.visitors[0]["kot"] = [];
                                    } else {
                                        tempCaptainItems.forEach(item => {
                                            var is_found = 0;
                                            for(var i=0;i<foundTable.visitors[0].dishes[0].length;i++) {                                  
                                                if(foundTable.visitors[0].dishes[0][i].item_code == item.item_code) {
                                                    foundTable.visitors[0].dishes[0][i] = item;
                                                    //console.log("item updated",item)
                                                    is_found = 1;
                                                    break;
                                                }
                                            }
                                            if(!is_found) { 
                                                foundTable.visitors[0].dishes[0].push(item); 
                                            }
                                        })
                                    }    
                                    this.startActivePhase(foundTable,tableinfo.started_at)                          
                                }
                            }
                        });
                    }
                    if(clearOrSaveTableFlag){
                        self.syncTable(foundTable,isAlreadySync);
                    }else{                        
                        self.syncParticularTable(sendData,clearOrSaveTableFlag,isAlreadySync);
                    }
                } else {
                    this.setInClearing(sendData,false)            
                }
            } else {
                this.setInClearing(sendData,false)            
            }
        } else {
            this.setInClearing(sendData,false)            
        }
       /* setTimeout(()=>{
            self.stateGuard.entrust("selects-box.component:currentUnit",foundTable);
            if(clearOrSaveTableFlag){
                var tempCurrentUnit = self.stateGuard.entrust("selects-box.component:currentUnit",foundTable);
                self.syncTable(tempCurrentUnit,isAlreadySync);
            }else{
                self.syncParticularTable(sendData,clearOrSaveTableFlag,isAlreadySync);
            }
        },300);*/
    }

    convertCaptainItemToPosItem(item) {
        var category_itemlist = JSON.parse(localStorage.getItem(item.category_code)) || [];
        var itemDetail = null;
        if(category_itemlist) {
            for(var i=0;i<category_itemlist.length;i++) {
                if(category_itemlist[i].item_code == item.item_code) {
                    let tempAttr = [];
                    if(category_itemlist[i].attributes && category_itemlist[i].attributes.length>0 && category_itemlist[i].attributes && item.attributes && item.attributes.length > 0) {
                        item.attributes.forEach(attr => {
                            let tempAttrInfo = category_itemlist[i].attributes.filter(at => at.attribute_code == attr.attribute_code)[0];  
                            if(tempAttrInfo) {
                                tempAttrInfo["count"] = attr.count;                  
                                tempAttr.push(tempAttrInfo);
                            }
                        })
                    }
                    itemDetail = category_itemlist[i];          
                    itemDetail = Object.assign(itemDetail,item);
                    itemDetail.attributes = tempAttr;
                    if(tempAttr.length>0) { 
                        itemDetail["isOnlyModifier"] = true; 
                    }
                    itemDetail.tempData = {
                        'temp_unit_price': (itemDetail.item_price)? itemDetail.item_price :parseFloat(itemDetail.unit_price),
                        'temp_item_count': itemDetail.item_count,
                        'unit_price':  (itemDetail.item_price)? itemDetail.item_price :parseFloat(itemDetail.unit_price)
                    };
                    break;
                }
            }
        }
        return itemDetail;
    }

    startActivePhase(unit,started_from) {   

        var ticker, previousePhasing = null,
        timeOut = setTimeout(function () {
            autoSwitchPhase(unit);
        }, 600000),
        autoSwitchPhase = function (unit) {
            unit.activePhase.name = "red";
        },
        timerPipe = function (time) {
            var hours = Math.floor(time / 3600),
            minutes = Math.floor((time - hours * 3600) / 60),
            seconds = time - hours * 3600 - minutes * 60;
            return ("00" + hours + ":").substr(-3) + ("00" + minutes + ":").substr(-3) + ("00" + seconds).substr(-2);
        },

        runTimer = function () {
            var theUnit = unit;
            return function () {
                ticker = setInterval(function () {
                    var time = Math.round((new Date().valueOf() - theUnit.activePhase.timeStamp) / 1000);
                    theUnit.activePhase.timerText = timerPipe(time);
                }, 1000);
                unit.activePhase.ticker = ticker;
            }
        };

        if (unit.activePhase) {
            previousePhasing = JSON.parse(JSON.stringify(unit.activePhase));
            this.interruptActivePhase(unit);
        }    
        if(!started_from) {
            started_from = new Date();
        }
        unit.activePhase = {
            name: previousePhasing ? previousePhasing.name : "green",
            timeOut,
            timeStamp: previousePhasing ? previousePhasing.timeStamp : new Date(started_from).valueOf(),
            timerText: previousePhasing ? previousePhasing.timerString : "00:00:00"
        };
        runTimer()();
    }

    interruptActivePhase(unit) {
        clearInterval(unit.activePhase.ticker);
        clearTimeout(unit.activePhase.timeOut);
        delete unit.activePhase;
    }

    isCaptainAppAvailable() {
        let shop_config:any = JSON.parse(localStorage.getItem('shop_configuration')) || {};
        let captain_server_url = localStorage.getItem('captain_server_url');
        return (captain_server_url && shop_config && shop_config.shop_info && shop_config.shop_info.is_captain_app_available); 
    }

    clearTable(tableinfo,foundTable) {
        var temp = {
            location: tableinfo.section_name,
            tables: foundTable.tables,
            assigny: '',
            lock_index: 0,
            chosen:false
        }
        if(foundTable.activePhase){
          if(foundTable.activePhase.ticker){
            clearInterval(foundTable.activePhase.ticker);
          }
          if(foundTable.activePhase.timeOut){
            clearTimeout(foundTable.activePhase.timeOut);
          }
          delete foundTable.activePhase;
        }

        for(var x in foundTable) {
            if(["location","tables","assigny","lock_index","chosen"].indexOf(x) == -1) {
                delete foundTable[x];
            }
        }

        foundTable = Object.assign(foundTable,temp);           
    }

   // TODO: Make the below function in websocket 
   // FIXME: It used to take http response from /update route and update the table data (UI) 

    // getCaptainTableList() {
    //     //console.log("getCaptainTableList")
    //     var self = this;
    //     var isCaptainAppAvailable =   this.isCaptainAppAvailable();
    //     if(isCaptainAppAvailable){
    //       this.captainAppService.getSyncTables({}).subscribe((res) => {
    //         var apiRes = res.json();
    //         if (apiRes.status === 0) {
    //           let allPlaces = this.stateGuard.obtain("selects-box.component:places") || [];

    //           if(apiRes.table_list && allPlaces) {
    //             for(var section in apiRes.table_list) {
    //               let captainSectionInfo = apiRes.table_list[section];
    //               let foundSection = allPlaces.filter(ap => ap.name == section)[0];
    //               if(foundSection && foundSection.units) {
    //                 captainSectionInfo.forEach(tableinfo => {

    //                     var foundTable = foundSection.units.filter(fs => fs.tables[0] == tableinfo.tables[0])[0];

    //                     if(foundTable && !foundTable.lock_index){
    //                         foundTable['lock_index'] = 0;
    //                     }                        
                        
    //                     if(foundTable && tableinfo.table_status == "idle" && foundTable.lock_index > 0 && tableinfo.lock_index == 0) {
    //                         this.clearTable(tableinfo,foundTable);
    //                     } else if(foundTable && foundTable.lock_index < tableinfo.lock_index && foundTable.table_status != 'merge' && !foundTable.is_clearing) {
    //                         //console.log("!!!!!!!!!!!!!!!!! "+JSON.stringify(foundTable));
    //                         //console.log("################# "+JSON.stringify(tableinfo));
    //                         let isSyncTable = false;
    //                         if(tableinfo.tables.length > 0) {
    //                             for(var i=1;i<tableinfo.tables.length;i++) {
    //                                 remove(foundSection.units, fs => fs.tables[0] == tableinfo.tables[i]);
    //                             }
    //                         }

    //                         let localConfig = JSON.parse(localStorage.getItem('local_configuration')) || {};
    //                         if(( !localConfig || localConfig.disable_captain_notifications != true) && !foundTable.is_checkout_call && tableinfo.is_checkout_call) {
    //                             let oInfo:any = {
    //                                 tables: tableinfo.tables,
    //                                 section_name: tableinfo.section_name,
    //                                 captain_user_name: tableinfo.captain_terminal && tableinfo.captain_terminal.terminal_name ? tableinfo.captain_terminal.terminal_name : ""
    //                             }
    //                             this.showNotificationForCheckOutOrder(JSON.parse(JSON.stringify(oInfo)));
    //                         }
    //                         foundTable.tables = tableinfo.tables;
    //                         foundTable.delivery_type = (foundTable.delivery_type)? foundTable.delivery_type : APIHelper.IMMEDIATE;
    //                         foundTable.isFromOnHold = (tableinfo.table_status == "idle") ? false : true;
    //                         foundTable.isOnHold = false;
    //                         foundTable.lock_index = tableinfo.lock_index;
    //                         foundTable.assigny = tableinfo.assigny;
    //                         foundTable.covers = tableinfo.covers;
    //                         foundTable.started_at = tableinfo.started_at;
    //                         foundTable.table_status = tableinfo.table_status;


    //                         if(tableinfo.customer_info && tableinfo.customer_info.phone) {
    //                             if(!foundTable.userInfo || !foundTable.userInfo.mobile_no) {
    //                                 foundTable.userInfo = {mobile_no:tableinfo.customer_info.phone,consumer_name:"Guest"};
    //                             }
    //                         }                            
    //                         foundTable.temp_client_reference_code = tableinfo.temp_client_reference_code;

    //                         /*foundTable['kot_request_number'] = tableinfo.kot_request_number;
    //                         foundTable['kot_ready_number'] = tableinfo.kot_ready_number;*/

    //                         if(tableinfo.is_checkout_call){
    //                           foundTable.is_checkout_call = true;
    //                           if(foundTable.activePhase && foundTable.activePhase.name){
    //                             foundTable.activePhase.name = 'blue';
    //                           }
    //                         } else {
    //                            foundTable.is_checkout_call = false;
    //                            if(foundTable.activePhase && foundTable.activePhase.name){
    //                                foundTable.activePhase.name = 'green';
    //                            } 
    //                         }

    //                         if(tableinfo.visitors) {
    //                             for (var k = 0; k < tableinfo.visitors.length; ++k) {
    //                                 if(k > 0){
    //                                     break;
    //                                 }
    //                                 if(k == 0){
    //                                     if(tableinfo.visitors[k].items) {
    //                                         //handle deleted_items;
    //                                       var captainItems = tableinfo.visitors[k].items.filter(vi => (vi.item_from == "captain" || vi.item_from == "pos"));
    //                                       var tempCaptainItems = [];
    //                                       if(captainItems) {
    //                                         captainItems.forEach(item => {
    //                                           item = this.convertCaptainItemToPosItem(item);
    //                                           if(item){
    //                                             tempCaptainItems.push(item);
    //                                           }

    //                                         })
    //                                         if(!foundTable.visitors) {
    //                                             foundTable.visitors = [{temp_client_reference_code:tableinfo.temp_client_reference_code}];
    //                                             foundTable.visitors[0]["dishes"] = [tempCaptainItems,[]];

    //                                             isSyncTable = true;
    //                                             if(tableinfo.visitors && Array.isArray(tableinfo.visitors)) {                                                                                        
    //                                                 var tempKotItem = (tableinfo.visitors && tableinfo.visitors[0] && Array.isArray(tableinfo.visitors[0]["kot"])) ? tableinfo.visitors[0]["kot"] : []
    //                                                 for(var i=0;i<tempKotItem.length;i++) {
    //                                                     for(var j=0;j<tempKotItem[i].items.length;j++){
    //                                                         tempKotItem[i]["items"][j] = this.convertCaptainItemToPosItem(tempKotItem[i]["items"][j]);
    //                                                     }
    //                                                 }                                          
    //                                                 foundTable.visitors[0]["kot"] = tempKotItem;
    //                                             } else {                                           
    //                                                 foundTable.visitors[0]["kot"] = [];
    //                                             }
    //                                         } else {
    //                                             remove(foundTable.visitors[0].dishes[0], it => it.item_from == "captain");
    //                                             let available_pos_items = remove(foundTable.visitors[0].dishes[0], it => it.item_from == "pos");
    //                                             let removeItems = tableinfo.visitors[0].deleted_items;

    //                                             if(Array.isArray(removeItems) && removeItems[0]){
    //                                                 for(var r of removeItems) {
    //                                                     remove((available_pos_items), d => {
    //                                                         return  (d.item_code === r.item_code && d.item_count == 1 && r.item_count == 1);
    //                                                     });
    //                                                 }
    //                                             }

    //                                             if(tableinfo.visitors && Array.isArray(tableinfo.visitors)) {                                                                                        
    //                                                 var tempKotItem = (tableinfo.visitors && tableinfo.visitors[0] && Array.isArray(tableinfo.visitors[0]["kot"])) ? tableinfo.visitors[0]["kot"] : []
    //                                                 for(var i=0;i<tempKotItem.length;i++) {
    //                                                     for(var j=0;j<tempKotItem[i].items.length;j++){
    //                                                         tempKotItem[i]["items"][j] = this.convertCaptainItemToPosItem(tempKotItem[i]["items"][j]);
    //                                                     }
    //                                                 }                                          
    //                                                 foundTable.visitors[0]["kot"] = tempKotItem;
    //                                             } else {                                           
    //                                                 foundTable.visitors[0]["kot"] = [];
    //                                             }

    //                                             tempCaptainItems.forEach(item => {
    //                                                 foundTable.visitors[0].dishes[0].push(item);
    //                                                 isSyncTable = true;
    //                                             });
    //                                             if(available_pos_items && available_pos_items[0]) {
    //                                                 available_pos_items.forEach(pos_item => {
    //                                                     let item = foundTable.visitors[0].dishes[0].filter(dish => 
    //                                                             (dish.item_code == pos_item.item_code && dish.item_from=='pos'));

    //                                                     if(item && item.length == 0){
    //                                                         foundTable.visitors[0].dishes[0].push(pos_item);
    //                                                     }
    //                                                 })
    //                                             }
    //                                         }
    //                                         this.startActivePhase(foundTable,tableinfo.started_at);                          
    //                                       }
    //                                     } 
    //                                 }                              
    //                             }
    //                         }

    //                         if(foundTable.visitors && foundTable.covers > 1 && foundTable.visitors.length != foundTable.covers){
    //                             for (var i = 0; i < foundTable.covers; ++i) {
    //                                 if(foundTable.visitors.length <= foundTable.covers){
    //                                     foundTable.visitors.push({dishes: [[],[]],kot:[],temp_client_reference_code:foundTable.temp_client_reference_code });
    //                                 }else{
    //                                     break;
    //                                 }
    //                             } 
    //                         }

    //                         // if(tableinfo.kot_ready_number < tableinfo.kot_request_number) { 
    //                         //     if(foundTable.visitors && foundTable.visitors[0]) {
    //                         //         foundTable.visitors[0].deleted_items = (tableinfo.visitors[0].deleted_items) ? tableinfo.visitors[0].deleted_items : [];
                                
    //                         //         this.kotHelper.captainKot(foundTable,tableinfo.kot_request_description).then(is_kot_done => {
    //                         //             foundTable.visitors.forEach(visitor => {
    //                         //                 if(Array.isArray(visitor.deleted_items)){
    //                         //                     /*visitor.deleted_items.forEach(item =>{
    //                         //                         item.is_used = 1;
    //                         //                     })*/
    //                         //                     visitor.deleted_items = [];
    //                         //                 }
    //                         //             })

    //                         //             foundTable['kot_request_number'] = tableinfo.kot_request_number;
    //                         //             //foundTable['kot_ready_number'] = tableinfo.kot_ready_number;
    //                         //             foundTable["kot_ready_number"] = tableinfo.kot_request_number;
    //                         //             foundTable["kot_request_description"] = "";
    //                         //             this.syncTable(foundTable,false);      
    //                         //         });
    //                         //         /*if(foundTable.visitors[0].deleted_items.length>0){
    //                         //             //this.checkForRemoveCaptainItems(foundTable,tableinfo);
    //                         //         }*/
    //                         //     }
    //                         // } else if(isSyncTable){
    //                             this.syncTable(foundTable,false);
    //                         //}                            
    //                     }
    //                 }) 
    //               }
    //             }
    //           }
    //         } 
    //         setTimeout(function(){
    //           self.getCaptainTableList();
    //         },5000);
    //       },err =>{
    //         console.log(err);
    //         setTimeout(function(){
    //           self.getCaptainTableList();
    //         },5000);
    //       });
    //     }else{
    //         setTimeout(function(){
    //           self.getCaptainTableList();
    //         },5000);
    //     }
    // }


    // TODO: HERE we have implemented the logic(similar to above) which will render the ui based on the response from the websocket
    getCaptainTableList() {
        console.log("getCaptainTableList    called");
        var self = this;
        var isCaptainAppAvailable = this.isCaptainAppAvailable();
        if(isCaptainAppAvailable){
          //FIXME: IMPLEMENT -> Subscribe to WebSocket messages
          this.subscriptions.push(                    
            this.webSocketService.getMessages().subscribe({
              next: (res) => {
                if (res.type === 'get_tables_response') {
                  var apiRes = res;
                  console.log("get_tables_response",apiRes);
                  if (apiRes.data.status === 0) {
                    console.log("get_tables_response",apiRes);
                    let allPlaces = this.stateGuard.obtain("selects-box.component:places") || [];
                    console.log("allPlaces",allPlaces);

                    if(apiRes.data.table_list && allPlaces) {
                      for(var section in apiRes.data.table_list) {
                        console.log("section",section);
                        let captainSectionInfo = apiRes.data.table_list[section];
                        let foundSection = allPlaces.filter(ap => ap.name == section)[0];
                        if(foundSection && foundSection.units) {
                          captainSectionInfo.forEach(tableinfo => {
                              var foundTable = foundSection.units.filter(fs => fs.tables[0] == tableinfo.tables[0])[0];
    
                              if(foundTable && !foundTable.lock_index){
                                  foundTable['lock_index'] = 0;
                              }                        
                              
                              if(foundTable && tableinfo.table_status == "idle" && foundTable.lock_index > 0 && tableinfo.lock_index == 0) {
                                  this.clearTable(tableinfo,foundTable);
                              } else if(foundTable && foundTable.lock_index < tableinfo.lock_index && foundTable.table_status != 'merge' && !foundTable.is_clearing) {
                                  let isSyncTable = false;
                                  if(tableinfo.tables.length > 0) {
                                      for(var i=1;i<tableinfo.tables.length;i++) {
                                          remove(foundSection.units, fs => fs.tables[0] == tableinfo.tables[i]);
                                      }
                                  }
    
                                  let localConfig = JSON.parse(localStorage.getItem('local_configuration')) || {};
                                  if(( !localConfig || localConfig.disable_captain_notifications != true) && !foundTable.is_checkout_call && tableinfo.is_checkout_call) {
                                      let oInfo:any = {
                                          tables: tableinfo.tables,
                                          section_name: tableinfo.section_name,
                                          captain_user_name: tableinfo.captain_terminal && tableinfo.captain_terminal.terminal_name ? tableinfo.captain_terminal.terminal_name : ""
                                      }
                                      this.showNotificationForCheckOutOrder(JSON.parse(JSON.stringify(oInfo)));
                                  }
                                  foundTable.tables = tableinfo.tables;
                                  foundTable.delivery_type = (foundTable.delivery_type)? foundTable.delivery_type : APIHelper.IMMEDIATE;
                                  foundTable.isFromOnHold = (tableinfo.table_status == "idle") ? false : true;
                                  foundTable.isOnHold = false;
                                  foundTable.lock_index = tableinfo.lock_index;
                                  foundTable.assigny = tableinfo.assigny;
                                  foundTable.covers = tableinfo.covers;
                                  foundTable.started_at = tableinfo.started_at;
                                  foundTable.table_status = tableinfo.table_status;
    
                                  if(tableinfo.customer_info && tableinfo.customer_info.phone) {
                                      if(!foundTable.userInfo || !foundTable.userInfo.mobile_no) {
                                          foundTable.userInfo = {mobile_no:tableinfo.customer_info.phone,consumer_name:"Guest"};
                                      }
                                  }                            
                                  foundTable.temp_client_reference_code = tableinfo.temp_client_reference_code;
    
                                  if(tableinfo.is_checkout_call){
                                    foundTable.is_checkout_call = true;
                                    if(foundTable.activePhase && foundTable.activePhase.name){
                                      foundTable.activePhase.name = 'blue';
                                    }
                                  } else {
                                     foundTable.is_checkout_call = false;
                                     if(foundTable.activePhase && foundTable.activePhase.name){
                                         foundTable.activePhase.name = 'green';
                                     } 
                                  }
    
                                  if(tableinfo.visitors) {
                                      for (var k = 0; k < tableinfo.visitors.length; ++k) {
                                          if(k > 0){
                                              break;
                                          }
                                          if(k == 0){
                                              if(tableinfo.visitors[k].items) {
                                                  var captainItems = tableinfo.visitors[k].items.filter(vi => (vi.item_from == "captain" || vi.item_from == "pos"));
                                                  var tempCaptainItems = [];
                                                  if(captainItems) {
                                                    captainItems.forEach(item => {
                                                      item = this.convertCaptainItemToPosItem(item);
                                                      if(item){
                                                        tempCaptainItems.push(item);
                                                      }
                                                    })
                                                    if(!foundTable.visitors) {
                                                        foundTable.visitors = [{temp_client_reference_code:tableinfo.temp_client_reference_code}];
                                                        foundTable.visitors[0]["dishes"] = [tempCaptainItems,[]];
    
                                                        isSyncTable = true;
                                                        if(tableinfo.visitors && Array.isArray(tableinfo.visitors)) {                                                                                        
                                                            var tempKotItem = (tableinfo.visitors && tableinfo.visitors[0] && Array.isArray(tableinfo.visitors[0]["kot"])) ? tableinfo.visitors[0]["kot"] : []
                                                            for(var i=0;i<tempKotItem.length;i++) {
                                                                for(var j=0;j<tempKotItem[i].items.length;j++){
                                                                    tempKotItem[i]["items"][j] = this.convertCaptainItemToPosItem(tempKotItem[i]["items"][j]);
                                                                }
                                                            }                                          
                                                            foundTable.visitors[0]["kot"] = tempKotItem;
                                                        } else {                                           
                                                            foundTable.visitors[0]["kot"] = [];
                                                        }
                                                    } else {
                                                        remove(foundTable.visitors[0].dishes[0], it => it.item_from == "captain");
                                                        let available_pos_items = remove(foundTable.visitors[0].dishes[0], it => it.item_from == "pos");
                                                        let removeItems = tableinfo.visitors[0].deleted_items;
    
                                                        if(Array.isArray(removeItems) && removeItems[0]){
                                                            for(var r of removeItems) {
                                                                remove((available_pos_items), d => {
                                                                    return  (d.item_code === r.item_code && d.item_count == 1 && r.item_count == 1);
                                                                });
                                                            }
                                                        }
    
                                                        if(tableinfo.visitors && Array.isArray(tableinfo.visitors)) {                                                                                        
                                                            var tempKotItem = (tableinfo.visitors && tableinfo.visitors[0] && Array.isArray(tableinfo.visitors[0]["kot"])) ? tableinfo.visitors[0]["kot"] : []
                                                            for(var i=0;i<tempKotItem.length;i++) {
                                                                for(var j=0;j<tempKotItem[i].items.length;j++){
                                                                    tempKotItem[i]["items"][j] = this.convertCaptainItemToPosItem(tempKotItem[i]["items"][j]);
                                                                }
                                                            }                                          
                                                            foundTable.visitors[0]["kot"] = tempKotItem;
                                                        } else {                                           
                                                            foundTable.visitors[0]["kot"] = [];
                                                        }
    
                                                        tempCaptainItems.forEach(item => {
                                                            foundTable.visitors[0].dishes[0].push(item);
                                                            isSyncTable = true;
                                                        });
                                                        if(available_pos_items && available_pos_items[0]) {
                                                            available_pos_items.forEach(pos_item => {
                                                                let item = foundTable.visitors[0].dishes[0].filter(dish => 
                                                                        (dish.item_code == pos_item.item_code && dish.item_from=='pos'));
    
                                                                if(item && item.length == 0){
                                                                    foundTable.visitors[0].dishes[0].push(pos_item);
                                                                }
                                                            })
                                                        }
                                                    }
                                                    this.startActivePhase(foundTable,tableinfo.started_at);                          
                                                  }
                                              } 
                                          }                              
                                      }
                                  }
    
                                  if(foundTable.visitors && foundTable.covers > 1 && foundTable.visitors.length != foundTable.covers){
                                      for (var i = 0; i < foundTable.covers; ++i) {
                                          if(foundTable.visitors.length <= foundTable.covers){
                                              foundTable.visitors.push({dishes: [[],[]],kot:[],temp_client_reference_code:foundTable.temp_client_reference_code });
                                          }else{
                                              break;
                                          }
                                      } 
                                  }
                                  
                                  this.syncTable(foundTable,false);                         
                              }
                          }) 
                        }
                      }
                    }
                  } 
                }
              },
              error: (err) => {
                console.log(err);
              }
            })
          );
        }
      }
    

    showNotificationForCheckOutOrder(oInfo) {
        let payload = {
            title: "Check out Table "+ oInfo.tables+", section "+oInfo.section_name,
            body: "Check out Table "+ oInfo.tables+", section "+oInfo.section_name+": "+oInfo.captain_user_name
        }
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            var notification = new Notification(payload.title, {
              body: payload.body,
              icon:"../assets/images/favicon.png"
            });
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
              // If the user accepts, let's create a notification
              if (permission === "granted") {
                var notification = new Notification(payload.title, {
                  body: payload.body,
                  icon:"../assets/images/favicon.png"
                });
              }
            });
        }   

        this.notificationSource.next({ notification: {notification:payload}});
    }

    getCaptainAppNotification() {
        return this.notificationSource.asObservable();
    }
    checkForRemoveCaptainItems(foundTable,tableinfo){

        let current_table_captain_items = [];
        let table_info_captain_items = [];

        if(foundTable.visitors && foundTable.visitors[0] && foundTable.visitors[0].dishes[0]){
            current_table_captain_items = foundTable.visitors[0].dishes[0].filter(currentItem => currentItem.item_from == 'captain');
        }

        if(tableinfo.visitors){
            for (var k = 0; k < tableinfo.visitors.length; ++k) {
                tableinfo.visitors[k].items.forEach(item => {
                    if(item.item_from == "captain"){
                        table_info_captain_items.push(item);
                    }
                })
            }
        }

        let item_code_list = [];
        table_info_captain_items.forEach(captain_item =>{
            var is_in_table = current_table_captain_items.filter( item => item.item_code == captain_item.item_code);
            if(is_in_table.length == 0){
                item_code_list.push(captain_item.item_code);
            }
        });

        if(foundTable.visitors && foundTable.visitors[0] && foundTable.visitors[0].dishes[0]){
            for (var i = 0; i < foundTable.visitors[0].dishes[0].length; ++i) {
                if(item_code_list.indexOf(foundTable.visitors[0].dishes[0][i].item_code) == -1){
                    this.removeDish(foundTable,i);
                }
            }
        }

    }

    removeDish(foundTable, dishIndex) {
        if(!foundTable.visitors[0].dishes[0][dishIndex].selected_variant_item){
            foundTable.visitors[0].dishes[0][dishIndex].expanded = false;
            foundTable.visitors[0].dishes[0][dishIndex].item_count = 1;
            foundTable.visitors[0].dishes[0][dishIndex].unit_price = foundTable.visitors[0].dishes[0][dishIndex].tempData.unit_price;
            foundTable.visitors[0].dishes[0][dishIndex].tempData.temp_unit_price = foundTable.visitors[0].dishes[0][dishIndex].tempData.unit_price;
        }else{
            foundTable.visitors[0].dishes[0][dishIndex].selected_variant_item.item_count =1;
            foundTable.visitors[0].dishes[0][dishIndex].selected_variant_item.unit_price = foundTable.visitors[0].dishes[0][dishIndex].tempData.unit_price;
        }
        foundTable.visitors[0].dishes[0].splice(dishIndex, 1);
    }


} 