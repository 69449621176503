export class BaseConfig {
    public static config = {
        /** API */
        /*API: 'https://api-au.onegreendiary.com/',
        REPORT_API: 'https://reports-au.onegreendiary.com/',*/
        API: 'https://api.onegreendiary.com/',
        REPORT_API: 'https://reportsapi.risehq.io/',
        // API: 'https://dev.onegreendiary.com/',
        PRINT_API: 'http://127.0.0.1:5000/print/',
        PRINT_KOT: 'kot',
        PRINT_BILL: 'bill', 
        PRINT_RECENT_BILL: 'recent-bill',
        PRINT_ITEM_REPORT: 'item-report',
        PRINT_CANCELLATION_REPORT: 'cancel-report',
        PRINT_SUMMARY_REPORT: 'summary-report',
        // REPORT_API:"http://devapi.onegreendiary.com:9337/",
        up_url:"https://devup.onegreendiary.com/",
         /*API: 'http://localhost:1337/',
        REPORT_API: 'http://localhost:3000/',*/
        /** Client */
        // OGD
        CLIENT: 'OneGreenDiary',
        firebaseConfig:{
            "apiKey": "AIzaSyCJAzRal28wMRPrFeBtbhU4pUjNxjhm6RI",
            "authDomain": "testwebpush-56f6f.firebaseapp.com",
            "databaseURL": "https://testwebpush-56f6f.firebaseio.com",
            "projectId": "testwebpush-56f6f",
            "storageBucket": "testwebpush-56f6f.appspot.com",
            "messagingSenderId": "761532239539",
            "appId": "1:761532239539:web:1ff5198ef15413807ceac6"
        }
        // RPOS
        // CLIENT: 'RapidPOS'
    };
}
